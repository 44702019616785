namespace UHTMLElement {
  export function scrollParents(element: HTMLElement) {
    const parents: HTMLElement[] = [];

    (function getParents(el: HTMLElement | null) {
      if (!el) return;

      if (el.scrollHeight > el.clientHeight) parents.push(el);

      getParents(el.parentElement);
    })(element);

    return parents;
  }
  scrollParents.listen = (element: HTMLElement, handler: () => void) => {
    const parents = UHTMLElement.scrollParents(element);
    for (const parent of parents) {
      parent.addEventListener("scroll", handler);
    }
    return {
      unlisten() {
        for (const parent of parents) {
          parent.removeEventListener("scroll", handler);
        }
      },
    };
  };
}

export default UHTMLElement;
