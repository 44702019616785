import { UpdateAddressRequest } from "@repo/protobuf/gen/web/public/onboarding_pb";
import { MessageFrom, PartialMessage } from "@repo/protobuf/utils";
import Address from "@repo/utils/Address";

export namespace AddressUsa {
  export const normalize = (
    proto: MessageFrom<UpdateAddressRequest>,
  ): Address => ({
    addressLine1: proto.addressLine1,
    addressLine2: proto.addressLine2,
    locality: proto.city,
    subdivision: proto.state,
    postalCode: proto.zip,
    country: "US",
  });
  export const denormalize = (
    address: Address,
  ): PartialMessage<UpdateAddressRequest> => ({
    addressLine1: address.addressLine1,
    addressLine2: address.addressLine2,
    city: address.locality,
    state: address.subdivision,
    zip: address.postalCode,
  });
}
